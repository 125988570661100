import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { B64B16DecoderProject } from "../components/Complete/B64B16DecoderProject";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <B64B16DecoderProject mdxType="B64B16DecoderProject" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      