import React from 'react'
import { Link } from 'gatsby'
import SEO from '../SEO'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { Navbar } from 'react-bootstrap'
import styled from 'styled-components' // 💅
import Footer from '../Footer'

const StyledB64B16DecoderProject = styled.div`
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  padding-bottom: 50px;
`
const Header = () => (
  <header style={{ background: `#000`, height: `64px` }}>
    <Navbar
      fixed="top"
      style={{
        background: `#000`,
        paddingBottom: `30px`,
        paddingTop: `10px`,
      }}
    >
      <Navbar.Brand>
        <Link
          to="/"
          style={{
            fontFamily: `Bungee Shade`,
            // fontFamily: `Londrina Outline`,
            // fontWeight: `700`,
            fontSize: '42px',
            color: `#65D9E9`,
            textDecoration: `none`,
          }}
        >
          <img
            style={{
              height: `80px`,
            }}
            src={'/logo.png'}
          />
        </Link>
      </Navbar.Brand>
    </Navbar>
  </header>
)

export const B64B16DecoderProject = () => (
  <>
    <Header />
    <SEO title="Project: base64 &amp; base16 decoder" />
    <StyledB64B16DecoderProject>
      <Container style={{ paddingTop: `170px` }}>
        <header>
          <div
            style={{
              fontFamily: `Rubik, sans-serif`,
            }}
          >
            <span
              style={{
                display: `inline`,
                fontSize: `24px`,
                marginRight: `5px`,
              }}
            >
              {' '}
              🏷{' '}
            </span>
            <span style={{ fontWeight: `400` }}>VanillaJS</span>
          </div>
          <h1
            style={{
              marginBottom: 0,
              fontFamily: `Rubik, sans-serif`,
              fontWeight: `300`,
              color: `#333`,
            }}
          >
            Project: base64 &amp; base16 decoder
          </h1>
          <p
            style={{
              display: `block`,
              fontFamily: `Roboto, sans-serif`,
              fontWeight: `300`,
              padding: `10px 0`,
            }}
          >
            July 03, 2020
          </p>
        </header>
      </Container>

      <Container fluid>
        <Row
          style={{
            padding: `50px 0`,
            margin: `170px 150px`,
            fontFamily: `'Open Sans', sans-serif`,
            fontWeight: `300`,
            background: `#c3c3cf`,
            borderRadius: `12px`,
          }}
        >
          <div className="desc col-sm-5">
            <h3>Features</h3>
            <p>As of now, it decodes base64 and base16 (hexstring).</p>
            <p>Copy decoded string on click.</p>
            <p>
              I am still learning about encodings, so the work is in progress.
            </p>
            <p>
              <b>WIP:</b> handling exceptions.
            </p>
          </div>
          <p className="col-sm-5">
            <iframe
              src="https://dumpofmemory.github.io/decoder/"
              height="600"
              width="770"
              title="Base64/Base16 Decoder Project"
            ></iframe>
          </p>
        </Row>
      </Container>
    </StyledB64B16DecoderProject>
    <Footer />
  </>
)
